<template>
  <div class="container container-onde-encontrar">
    <h3 class="titulo">Onde Encontrar</h3>
    <div class="conteudo">
      <p class="paragrafo-descricao">Veja aqui como é facil nos encontrar e fazer uma visita. </p>
      <iframe id="maps-localizacao-java"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3573.564397196036!2d-49.115575199999995!3d-26.4052573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94de97ef04b090e7%3A0xf511779ad4b088ac!2sEmbutidos%20JAVA!5e0!3m2!1spt-BR!2sbr!4v1633800518994!5m2!1spt-BR!2sbr"
        allowfullscreen="" loading="lazy">
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "OndeEncontrarComponent",
}
</script>

<style scoped lang="scss">
.container-onde-encontrar {

  .conteudo {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    #maps-localizacao-java {
      border: unset;
      height: 40rem;
      border-radius: 0.5rem;
      box-shadow: 0 0 15px -5px var(--cor-tema-shadow);
    }
  }
}
</style>