<template>
  <div class="container container-redes-sociais">
    <!-- <h3 class="titulo">Redes Sociais</h3> -->
    <div class="conteudo">
      <p class="paragrafo-descricao">Conheça nosso trabalho nas redes sociais.</p>
      <div class="conteudo-redes-sociais">

        <div class="div-btn-social">
          <a class="btn-social btn-whatsapp" target="_blank" href="https://api.whatsapp.com/send?phone=55047991947779">
            <div class="div-social-icon">
              <i class="fab fa-whatsapp"></i>
            </div>
          </a>
        </div>

        <div class="div-btn-social">
          <a class="btn-social btn-facebook" target="_blank" href="https://www.facebook.com/Embutidosjava/">
            <div class="div-social-icon">
              <i class="fab fa-facebook-f"></i>
            </div>
          </a>
        </div>

        <div class="div-btn-social">
          <a class="btn-social btn-instagram" target="_blank" href="https://www.instagram.com/javaembutidos/">
            <div class="div-social-icon">
              <i class="fab fa-instagram"></i>
            </div>
          </a>
        </div>

        <div class="div-btn-social">
          <a class="btn-social btn-mail" target="_blank" href="mailto:comercial@javaembutidos.com.br">
            <div class="div-social-icon">
              <i class="fa-regular fa-envelope"></i>
            </div>
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RedesSociaisComponent",
}
</script>

<style scoped lang="scss">
.container-redes-sociais {
  display: flex;
  flex-direction: column;
  color: var(--cor-texto);
  font-weight: 500;

  .conteudo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;


    .conteudo-redes-sociais {
      display: flex;
      align-items: center;
      gap: 1rem;

      .btn-social {
        width: 4.5rem;
        display: grid;
        gap: .25rem;
        text-decoration: none;
        align-items: center;
        justify-items: center;

        &:hover {
          filter: brightness(.9);
        }
      }

      .div-social-icon {
        height: 3.2rem;
        width: 3.2rem;
        font-size: 1.5rem;
        border-radius: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: var(--cor-tema-terciaria);
      }

      .div-social-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--cor-texto);
        font-size: 1rem;
        font-weight: 400;
      }

      .btn-social {
        &.btn-whatsapp {
          .div-social-icon {
            background-color: #25D366;
          }
        }

        &.btn-facebook {
          .div-social-icon {
            background-color: #3b5998;
          }
        }

        &.btn-instagram {
          .div-social-icon {
            background-color: #f30973;
          }
        }

        &.btn-mail {
          .div-social-icon {
            background-color: #009db7;
          }
        }
      }
    }
  }
}
</style>