<template>
  <div class="container container-sobre-nos">
    <h3 class="titulo"> Sobre Nós </h3>
    <div class="conteudo">
      <p class="paragrafo-descricao">
        A Embutidos Java está no mercado para proporcionar aos clientes uma experiência gastronômica completa ao
        entregar ao consumidor produtos feitos com ingredientes selecionados. Nossas combinações de sabores existem
        desde muito antes da criação da empresa, em 2020. Somos descendentes de italianos que chegaram ao Brasil com
        muitos sonhos na bagagem e uma enorme disposição para o trabalho. Além disso, trouxeram consigo as receitas que,
        mais de 100 anos depois, são produzidas da nossa família para a sua.

        Apesar de estarmos formalmente no mercado desde 2020, a ideia da empresa surgiu em 2017. Desde lá, incontáveis
        foram as degustações entre amigos para testar as adaptações das receitas originais. Com o tempo e com o trabalho
        cuidadoso, nossos produtos se tornaram alinhados ao paladar contemporâneo, sem perder a essência dos embutidos
        produzidos no início do século passado por aqueles que chegaram nesta terra esperançando uma vida melhor. Assim,
        a Embutidos Java perpetua o legado de quem nos deixou de herança a inspiração para as nossas receitas e torna
        realidade a vontade de dividir com nossos clientes os sabores presentes na nossa história.
      </p>
    </div>
    <div class="conteudo-adicional">

      <div class="box box-missao">
        <h4 class="titulo-box">Missão</h4>
        <span class="icon">
          <i class="fa-solid fa-check"></i>
        </span>
        <p class="conteudo-box">
          Proporcionar satisfação aos consumidores e colaboradores através de produtos artesanais saudáveis e de
          qualidade exclusiva.
        </p>
      </div>

      <div class="box box-visao">
        <h4 class="titulo-box">Visão</h4>
        <span class="icon">
          <i class="fa-solid fa-eye"></i>
        </span>
        <p class="conteudo-box">
          Integrar a tradição aos moldes contemporâneos de produção de alimentos para conectar gerações.
        </p>
      </div>

      <div class="box box-valor">
        <h4 class="titulo-box">Valores</h4>
        <span class="icon">
          <i class="fa-solid fa-heart"></i>
        </span>
        <p class="conteudo-box">
          Amor pelo que fazemos, responsabilidade, confiança, qualidade, tradição.
        </p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "SobreNosComponent",
}
</script>

<style scoped lang="scss">
.container-sobre-nos {
  .conteudo-adicional {
    padding: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;

    .box {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      max-width: 350px;

      .icon {
        color: var(--cor-tema-terciaria);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
      }

      .titulo-box {
        color: var(--cor-tema-shadow);
        font-size: 1.5rem;
      }
    }
  }
}
</style>