<template>
  <section class="scroll-top-component" title="Subir para o topo">
    <div id="scrolltop" @click="this.scrollTopTop">
      <i class="fa-solid fa-circle-up"></i>
    </div>
  </section>
</template>

<script>
export default {
  name: "ScrollToTopComponent",
  methods: {
    scrollTopTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
</script>


<style scoped lang="scss">
.scroll-top-component {
  display: none;
  display: block;
  z-index: 50;
  position: fixed;
  right: 5rem;
  bottom: 5rem;
  height: 0;
  width: 0;
  font-size: 3rem;
  color: var(--cor-tema-secundaria);
  opacity: .3;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
</style>