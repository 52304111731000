<template>
  <div class="container container-contato">
    <h3 class="titulo">Contato</h3>
    <div class="conteudo">
      <p class="paragrafo-descricao">Ficou com dúvida? Fale conosco!</p>
      <div class="conteudo-contato">

        <div class="formulario-contato">
          <form @submit="this.submitContato">
            <div class="form-floating mb-3">
              <input type="name" class="form-control" id="campo-nome" placeholder="Nome" autocomplete="off" required>
              <label for="campo-nome">Nome</label>
            </div>

            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="campo-mail" placeholder="email@email.com" autocomplete="off" required>
              <label for="campo-mail">E-mail</label>
            </div>

            <div class="form-floating mb-3">
              <input type="tel" class="form-control" id="campo-fone" placeholder="telefone" autocomplete="off">
              <label for="campo-fone">Telefone</label>
            </div>

            <div class="form-floating mb-3">
              <textarea class="form-control" placeholder="Deixe seu comentário" id="campo-comentario" autocomplete="off" required></textarea>
              <label for="campo-comentario">Comentário</label>
            </div>

            <div class="container-submit">
              <button type="submit" class="btn btn-enviar">Enviar</button>
            </div>

          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContatoComponent",

  methods:{
    submitContato(event){
      /* Previne o default para não disparar o envio */
      event.preventDefault();
            debugger;
    },
  },

  mounted() {
    var maskOptionTelefone = {
      onKeyPress: function (tel, e, field, maskOptionTelefone) {
        var masks = ['(00) 0000-0000#', '(00) 00000-0000'];
        var mask = (tel.length > 14) ? masks[1] : masks[0];
        $('#campo-fone').mask(mask, maskOptionTelefone);
      }
    };
    
    $('#campo-fone').mask('(00) 00000-0000', maskOptionTelefone);
  }
}
</script>

<style scoped lang="scss">
.container-contato {
  display: flex;
  flex-direction: column;

  .conteudo {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button:hover,
    .btn-social:hover {
      filter: brightness(.9);
    }

    .conteudo-contato {
      display: grid;
      gap: 1rem;

      .form-control {
        background-color: rgba(255, 255, 255, 60%);
      }

      textarea {
        resize: none;
        height: 10rem;
      }

      .container-submit {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          background-color: var(--cor-tema-terciaria);
          color: var(--cor-texto);
          width: 10rem;
          margin: 1rem 0;
        }
      }
    }

  }
}
</style>