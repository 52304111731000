<template>
  <header class="header-menu-principal">
    <nav class="conteudo-menu-mobile" v-if="this.isMobile">
      <div class="logo-menu" @click="this.mostraMenu">
        <i class="fa fas fa-house"></i>
      </div>
      <div class="container-menu">
        <BackgroundComponent opacidade=".7" />

        <router-link class=" router router-home" title="Página Inicial" to="/">Inicío</router-link>

        <router-link v-for="indice in this.rotas" class="router" :title="indice.descricao"
          :to="{ name: 'home', hash: indice.rota }"> {{ indice.descricao }} </router-link>

        <a class=" router" title="Catálogo" href="/assets/doc/catalogo_embutidos_java.pdf"
          download='Java Embutidos - Catálogo de Produtos.pdf' to="/">Catálogo</a>
      </div>
    </nav>

    <nav class="conteudo-menu" v-else>
      <router-link class=" router router-home" title="Página Inicial" v-html='`<i class="fa fas fa-house"></i>`'
        to="/"></router-link>

      <router-link v-for="indice in this.rotas" class="router" :title="indice.descricao"
        :to="{ name: 'home', hash: indice.rota }"> {{ indice.descricao }} </router-link>

      <a class=" router" title="Catálogo" href="/assets/doc/catalogo_embutidos_java.pdf"
        download='Java Embutidos - Catálogo de Produtos.pdf' to="/">Catálogo</a>
    </nav>

  </header>
</template>

<script>
import BackgroundComponent from './BackgroundComponent.vue';


export default {
  name: "HeaderComponent",
  components: { BackgroundComponent },
  data() {
    return {
      rotas: [
        { descricao: "Sobre Nós", rota: "sobre-nos" },
        { descricao: "Produtos", rota: "produtos" },
        { descricao: "Onde Encontrar", rota: "onde-encontrar" },
        // { descricao: "Contato", rota: "contato" },
      ],
      largura: false
    };
  },

  computed: {
    isMobile() {
      return this.largura < 660;
    }
  },

  methods: {
    mostraMenu() {
      let body = $('body');
      let menu = $(".container-menu");

      if (menu.css('height') > '0px') {
        menu.css('height', '0');
        body.css('overflow', 'auto')
      } else {
        menu.css('height', '100vh');
        body.css('overflow', 'hidden')
      }
    }
  },

  mounted() {
    this.largura = window.innerWidth;

    window.onresize = () => {
      this.largura = window.innerWidth;
    }
  }

}
</script>

<style lang="scss">
.header-menu-principal {
  position: relative;
  display: flex;
  z-index: 1;
  width: 100%;
  height: 4rem;
  background-color: var(--cor-tema-base);
  color: var(--cor-texto);
  box-shadow: 0 0 15px 5px hsl(11deg 43% 20%);

  .router {
    cursor: pointer;
    text-decoration: none;
    color: var(--cor-texto);
    font-weight: 500;

    &:hover {
      color: var(--cor-tema-secundaria);
    }
  }

  .conteudo-menu {
    flex: 1;
    position: relative;
    margin: 0 auto;
    max-width: var(--width-desktop-max);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .router-home {
      i {
        font-size: 20px;
      }
    }
  }

  .conteudo-menu-mobile {
    .logo-menu {
      padding-left: 15px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      font-size: 1.5rem;
      font-weight: 600;
      height: 100%;
    }

    .container-menu {
      width: 100vw;
      height: 0;
      position: absolute;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      transition: .3s ease-in-out;

      .router {
        padding-top: 1rem;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }
}
</style>