window.scrollToTop = (top) => {
  window.scrollTo({ top: top, behavior: 'smooth' });
};

zoomInImage = (src, descricao) => {
  let oContainerZoom = $("<div>");
  oContainerZoom.on("click", () => {
    oContainerZoom.detach();
  });
  oContainerZoom.addClass("container-zoom");
  oContainerZoom.css({
    "overflow": "hidden",
    "height": "100vh",
    "width": "100vw",
    "position": "fixed",
    "z-index": 99,
    "top": 0,
    "left": 0,
    "display": "flex",
    "flex-direction": "column",
    "background-color": "rgb(0 0 0 / 85%)"
  });

  let oContainerTop = $("<div>");
  oContainerTop.addClass("container-top");
  oContainerTop.on("click", () => {
    oContainerZoom.detach();
  });
  oContainerTop.css({
    "display": "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    "height": "3rem"
  });

  let oIconCloseContainerZoom = $("<i class='fas fa-times'></i>");
  oIconCloseContainerZoom.css({
    "font-size": "25px",
    "color": "#FFF",
    "position": "relative",
    "top": "5px",
    "right": "15px",
    "cursor": "pointer"
  });
  oContainerTop.append(oIconCloseContainerZoom);

  let oContainerImage = $("<div>");
  oContainerImage.addClass("container-image");
  oContainerImage.css({
    "display": "flex",
    "flex": "1",
    "align-items": "center",
    "justify-content": "center",
    "flex-direction": "column",
    "color": "#fff"
  });

  let oImage = $("<img>");
  oImage.attr("src", src)
  oImage.addClass("zoom-image");
  oImage.css({
    "max-width": "100vw",
    "max-height": "calc(100vh - 4rem)"
  });
  oContainerImage.append(oImage);

  let oImageDescricao = $("<span>");
  oImageDescricao.addClass("descricao-image");
  oImageDescricao.text(descricao);
  oImageDescricao.css({
    "padding": "0.5rem"
  });
  oContainerImage.append(oImageDescricao);

  oContainerZoom.append([oContainerTop, oContainerImage]);
  $("body").append(oContainerZoom);
};