<template>
  <main class="container-conteudo">
    <section class="container-produtos">
      <div class="moldura-titulo">
        <span class="moldura-linha-produto">
          <span class="titulo-linha">
            <p>
              {{ this.tituloLinha }}
            </p>
          </span>
        </span>
      </div>

      <div v-for="(item, index) of produtos" :class="`item-produto item-produto-${index % 2 === 0 ? 'par' : 'impar'}`">
        <img class="imagem-produto" 
             :src="`/assets/images/${this.$route.params.linha}/${item.img}`" 
             :alt="item.produto" 
             @click="zoomInClick(`/assets/images/${this.$route.params.linha}/${item.img}`, item.produto)"
        />
        <div class="conteudo-produto">
          <h3 class="titulo-produto">{{ item.produto }}</h3>
          <p class="descricao-produto">{{ item.descricao }}</p>
          <div class="formato-venda" v-if="item.tipo && item.tipo.length > 0">
            <p class="titulo-formato-venda">Formas de comércio</p>
            <ul class="lista-produto">
              <li v-for="tipo in item.tipo" class="item-lista-produto">{{ tipo }}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <ScrollToTopComponent/>
  </main>
</template>

<script>
import ContatoComponent from '@/components/ContatoComponent.vue';
import ScrollToTopComponent from '@/components/ScrollToTopComponent.vue';

export default {
  name: 'LinhaProdutoView',
  components: {
    ContatoComponent,
    ScrollToTopComponent
},

  data() {
    return {
      linhaProduto: false,
      produtos: this.getDadosLinhaProduto()
    }
  },

  computed: {
    tituloLinha() {
      let tituloLinha = [];
      tituloLinha['defumada'] = 'Linha Defumada';
      tituloLinha['frescal'] = 'Linha Frescal';
      return tituloLinha[this.getLinhaFromParametros()];
    }
  },

  methods: {
    zoomInClick(src, desc){
      zoomInImage(src, desc);
    },

    getDadosLinhaProduto() {
      let aDados = [];
      aDados['defumada'] = [
        {
          produto: `Linguiça Defumada`,
          descricao: `A linguiça defumada JAVA é produzida com cortes especiais de lombo e pernil suíno, passando por um processo mínimo de defumação de 48 horas realizado em madeira que são selecionadas para dar maior sabor aos produtos. Um processo que passa a gerações deixando o produto com sabor leve e sabor de defumado natural. NÃO SÃO UTILIZADOS AROMAS ARTIFICIAIS.`,
          img: `linguica.webp`
        },
        {
          produto: `Salame Italiano`,
          descricao: ` O salame italiano JAVA é produzido com nobres de lombo e pernil moído grosso, também conta com cubos de gordura contribuindo suculência para o produto. O processo mínimo de defumação e de 48 horas realizado em madeira selecionada para dar maior sabor. Esse processo permite que durante a cura surjam as características harmoniosas entre os temperos e a carne. NÃO SÃO UTILIZADOS AROMAS ARTIFICIAIS NEM CORANTES. `,
          tipo: [
            'Salame Italiano inteiro',
            'Salame Italiano a vácuo',
            'Salame Italiano fatiado',
          ],
          img: `salame.webp`
        },
        {
          produto: `Costela Defumada`,
          descricao: `A costelinha defumada JAVA passa por um processo mínimo de defumação de 96 horas realizado em madeira. O processo exige acompanhamento de temperatura e densidade da fumaça garantindo um produto de qualidade e sabor suave com gostinho de defumado natural. Ideal para compor uma feijoada e elaborar pratos típicos`,
          tipo: [
            'Costela ripa - Cry'
          ],
          img: `costela.webp`
        },
        {
          produto: `Bacon Defumado`,
          descricao: `O bacon defumado JAVA é produzido com peças selecionados de barriga suína levando um método totalmente artesanal, desde a seleção da matéria prima a defumação realizada em madeiras que são selecionadas para dar maior sabor aos produtos. O processo de defumação leva 96h envolvendo o monitoramento da temperatura e densidade da fumaça, garantindo um produto de qualidade e sabor artesanal.`,
          tipo: [
            'Bacon em cubos 200g',
            'Bacon fatiado',
            'Bacon em pedaço - Cry',
          ],
          img: `bacon.webp`
        },
        {
          produto: `Lombo Defumado`,
          descricao: `O Lombo defumado JAVA é produzido com peças selecionados de suínos maduros onde possuem um sabor mais acentuado deixando o paladar único. Perfeito para compor tábuas de frios.`,
          tipo: [
            'Lombo em pedaço - Cry',
            'Lombo Fatiado',
          ],
          img: `lombo.webp`
        },
        {
          produto: `Copa Defumada`,
          descricao: `A copa defumada JAVA é produzida com peças selecionados levando um método totalmente artesanal, desde a seleção da matéria prima a defumação realizada em madeiras que são selecionadas para dar maior sabor aos produtos. O processo de defumação leva 96h envolvendo o monitoramento da temperatura e densidade da fumaça, garantindo um produto de qualidade e sabor artesanal. (marmoreiro)`,
          tipo: [
            'Copa em pedaço - Cry'
          ],
          img: `copa.webp`
        },
        {
          produto: `Calabresa`,
          descricao: `A linguiça calabresa é um tipo de carne muito saborosa e famosa no Brasil, Feita com carne suína junto a alguns agregados de temperos, defumada no ponto certo, É um tipo de linguiça que cai bem com diversos outros aperitivos como queijos, tomates, lanches, massas, feijoadas etc.`,
          img: `calabresa.webp`
        },
      ];

      aDados['frescal'] = [
        {
          produto: `Linguiça Campeira`,
          descricao: `A linguiça campeira JAVA apresenta cortes selecionados de pernil e lombo de suínos jovem separados manualmente e cortado a faca, na degustação sente se os tenros pedaços de carne, temperados com especiarias, sem adição de corantes e proteínas, 100% carne suína.`,
          tipo: [
            'Campeira de queijo coalho',
            'Alho e óleo',
            'Alho poró',
            'Tomate seco',
            'Bacon',
          ],
          img: `campeira.webp`
        },
        {
          produto: `Linguiça Fininha`,
          descricao: `A linguiça fininha JAVA é embutida com tripa de carneiro, deixando a mordida muito mais leve e saborosa, produzida cortes de lombo e copa, com blend de carnes moídas em discos diversos, fazendo que o item tenha uma degustação prazerosa e de rápido cozimento.`,
          img: `fininha.webp`
        },
        {
          produto: `Linguiça Fininha Apimentada`,
          descricao: `A linguiça fininha apimentada JAVA é embutida com tripa de carneiro, sendo produzida com pimenta calabresa e cortes de lombo e copa, com blend de carnes moídas em discos diversos, fazendo que o item tenha uma degustação prazerosa e de rápido cozimento.`,
          img: `fininha-apimentada.webp`
        },
        {
          produto: `Linguiça de Pernil`,
          descricao: `Produzida somente com blend de pernil suíno selecionados a linguiça conta com temperos especiais elaborados, sem adição de corantes e proteínas. A carne dá linguiça de pernil é moída num disco mais grosso, conseguindo sentir os pedacinhos de carne.`,
          img: `pernil.webp`
        },
        {
          produto: `Linguiça Toscana`,
          descricao: `Produzida somente com blend de pernil e toucinho suíno selecionados a linguiça conta com temperos especiais. Sem adição de corantes e proteínas.`,
          img: `toscana.jpg`
        },
        {
          produto: `Linguiça de Frango`,
          descricao: `Sabor suave e textura macia, estas são as principais características da linguiça de frango, elaborada com partes nobres do frango e temperos exclusivos.  Sem adição de corantes e proteínas.`,
          tipo: [
            "Frango",
            "Frango com Bacon"
          ],
          img: `frango.jpg`
        },
        {
          produto: `Morcela`,
          descricao: `A morcela suína JAVA é produzida com um blend minunciosamente elaborado com miúdos e carne suína, unindo receita artesanal de família com moderna forma de embutir, deixam um sabor muito agradável e leve. Qualidade garantida!`,
          img: `morcela.webp`
        },
        {
          produto: `Carnes Temperadas`,
          descricao: `A fim de facilitar o dia a dia corrido das famílias brasileiras, temos cortes de carnes selecionadas, com um tempero natural e saboroso. Perfeito para qualquer ocasião. Sem adição de corantes e proteínas.`,
          img: `carne.jpg`
        },
      ];

      return aDados[this.linhaProduto];
    },

    getLinhaFromParametros() {
      let parametrosURL = this.$route.params;
      if (parametrosURL && parametrosURL.linha) {
        return parametrosURL.linha;
      }
    },

  },

  beforeCreate() {
    let parametrosURL = this.$route.params;
    if (parametrosURL && parametrosURL.linha) {
      /* Define a linha de produto que vier por parametro na url */
      this.linhaProduto = parametrosURL.linha;
    } else {
      /* Volta para home */
      window.location.hash = '#/';
    }
  }
}
</script>

<style lang="scss">
.container-produtos {
  width: 100%;
  max-width: var(--width-desktop-max);
  display: flex;
  gap: 3rem;
  flex-direction: column;
  margin: 0 0 5rem 0;

  .moldura-titulo {
    margin: 0 auto;

    .moldura-linha-produto {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .25rem;
      margin-top: 3rem;
      background-image: url(@/assets/images/background-madeira-linhas.jpg);
      
      p {
        background-image: url(@/assets/images/old-paper-rodape.webp);
        background-size: 100%;
        background-color: var(--cor-texto);
        mix-blend-mode:screen;
        color: black;
        font-size: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 500;
        padding: 0 1.5rem;
      }
    }
  }

  .item-produto {
    display: grid;
    margin: 0 5rem;
    padding: 0rem;
    border-radius: 5px;

    .imagem-produto {
      width: 100%;
      height: 22rem;
      object-fit: cover;
      box-shadow: 0 0 15px -3px var(--cor-tema-shadow);
    }

    .conteudo-produto {
      padding: 1rem 2em;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .titulo-produto {
        color: var(--cor-tema-base);
        font-weight: 500 !important;
        font-size: 1.5rem;
      }

      .descricao-produto {
        text-align: justify;
      }


      .formato-venda {
        text-align: initial;

        p.titulo-formato-venda {
          font-size: 1.1rem;
          font-weight: 500 !important;
          color: var(--cor-tema-base);
        }

        .lista-produto {

          .item-lista-produto::before {
            font: var(--fa-font-solid);
            content: '\f0da';
            margin: 0 .5rem;
            color: var(--cor-tema-terciaria);
          }
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .item-produto {

    &.item-produto-par {
      grid-template-columns: 45% 55%;
      background: linear-gradient(90deg, transparent, #6e4f3942);

      .imagem-produto {
        grid-column: 1/2;
        grid-row: 1/2;
        border-radius: 5px 0px 0px 5px;
      }

      .conteudo-produto {
        grid-column: 2/3;
        grid-row: 1/2;
      }
    }

    &.item-produto-impar {
      grid-template-columns: 55% 45%;
      background: linear-gradient(90deg, #6e4f3942, transparent);

      .imagem-produto {
        grid-column: 2/3;
        grid-row: 1/2;
        border-radius: 0px 5px 5px 0px;
      }

      .conteudo-produto {
        grid-column: 1/2;
        grid-row: 1/2;
      }
    }
  }
}

@media (max-width: 1000px) {
  .container-produtos {
    margin: 0 0 3rem 0;

    .imagem-produto{
      border-radius: 5px 5px 0px 0px;
    }

    .item-produto {
      margin: 0 3rem;
      grid-template-columns: 1fr;
      background: linear-gradient(180deg, transparent, rgba(110, 79, 57, 0.2588235294));
      grid-template-rows: auto auto;
    }
  }
}

@media (max-width: 600px) {
  .container-produtos {
    margin: 0 0 1rem 0;

    .item-produto {
      margin: 0 1rem;
    }
  }
}
</style>