<template>
  <BackgroundComponent/>
  <HeaderComponent />
  <router-view />
  <FooterComponent />
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import BackgroundComponent from './components/BackgroundComponent.vue';
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    BackgroundComponent,
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/App.scss";
</style>
