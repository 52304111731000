<template>
  <main class="container-conteudo">
    <section class="section-conteudo section-logo-java" id="home">
      <img class="image-container-logo" src="/assets/images/logo_parma-g.webp">
    </section>

    <section class="section-conteudo sobre-nos" id="section-sobre-nos">
      <SobreNosComponent />
    </section>

    <section class="section-conteudo produtos" id="section-produtos">
      <ProdutoComponent />
    </section>

    <section class="section-conteudo onde-encontrar" id="section-onde-encontrar">
      <OndeEncontrarComponent/>
    </section>

    <!-- <section class="section-conteudo contato" id="section-contato">
      <ContatoComponent/>
    </section> -->
    
    <ScrollToTopComponent/>
  </main>
</template>

<script>
import ContatoComponent from '@/components/ContatoComponent.vue';
import RedesSociaisComponent from '@/components/RedesSociaisComponent.vue';
import OndeEncontrarComponent from '@/components/OndeEncontrarComponent.vue';
import ProdutoComponent from '@/components/ProdutoComponent.vue';
import SobreNosComponent from '@/components/SobreNosComponent.vue';
import ScrollToTopComponent from '@/components/ScrollToTopComponent.vue';

export default {
  name: 'HomeView',
  components: {
    SobreNosComponent,
    ProdutoComponent,
    OndeEncontrarComponent,
    ContatoComponent,
    RedesSociaisComponent,
    ScrollToTopComponent
},
}
</script>

<style lang="scss">
@import '@/assets/styles/Main.scss';
</style>