import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/script/funcao.js'

/**
 * JQuery
 */
import $ from "jquery";
window.$ = $;

/**
 * JQuery Mask
 */
import "jquery-mask-plugin"

createApp(App)
  .use(router)
  .mount('#app')
