import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LinhaProdutoView from '../views/LinhaProdutoView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/produtos',
    name: 'produtos',
    component: HomeView
  },
  {
    path: '/sobre-nos',
    name: 'sobre-nos',
    component: HomeView
  },
  {
    path: '/onde-encontrar',
    name: 'onde-encontrar',
    component: HomeView
  },
  {
    path: '/contato',
    name: 'contato',
    component: HomeView
  },
  {
    path: '/linha-de-produto/:linha',
    name: 'linha-de-produto',
    component: LinhaProdutoView
  },


  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const scrollBehavior = (to, from, savedPosition) => {
  hideMenu();
  if (to.hash) {
    return window.scrollTo({
      top: document.getElementById(`section-${to.hash}`).offsetTop,
      behavior: 'smooth'
    })
  } else if (savedPosition && savedPosition.top) {
    return window.scrollTo({
      top: savedPosition.top,
      behavior: 'smooth'
    })
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}

const hideMenu = () => {
  let body = $('body');
  let menu = $(".container-menu");
  menu.css('height', '0');
  body.css('overflow', 'auto')
}

const router = createRouter({
  routes,
  history: createWebHashHistory(),
  scrollBehavior
});

export default router
