<template>
  <div class="container-background-principal">
    <div class="imagem-background-principal" :style="`opacity: ${this.opacidade ? this.opacidade : '.5'}`"></div>
  </div>
</template>

<script>
export default {
  name: "BackgroundComponent",
  props:{
    opacidade: String
  }
}
</script>

<style scoped lang="scss">
.container-background-principal {
  background-color: #291f0080;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;

  .imagem-background-principal {
    height: 100%;
    width: 100%;
    background-image: url(@/assets/images/background-madeira.jpg);
    background-repeat: repeat;
    background-size: 100%;
  }
}
</style>