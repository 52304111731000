<template>
  <footer class="footer-website">
    <img class="image-container-logo" src="/assets/images/favicon.ico">

    <RedesSociaisComponent />

    <div class="footer-superior">
      <div class="conteudo-footer-superior">
        <div class="horario-atendimento grupo-footer">
          <span class="titulo">Horário de Atendimento:</span>
          <div class="dia-semana grupo-item">
            <span class="item-titulo">Segunda à Sexta-Feira</span>
            <span class="item-conteudo">07:00 às 12:00 - 13:00 às 19:00</span>
          </div>
          <div class="fim-semana grupo-item">
            <span class="item-titulo">Sábados</span>
            <span class="item-conteudo">08:00 às 12:00</span>
          </div>
        </div>

        <div class="contato grupo-footer">
          <span class="titulo">Contato</span>
          <div class="contato-email grupo-item">
            <span class="item-titulo">E-mail</span>
            <span class="item-conteudo">comercial@javaembutidos.com.br</span>
          </div>
          <div class="contato-telefone grupo-item">
            <span class="item-titulo">Telefone</span>
            <span class="item-conteudo">(47) 9 9194-7779</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-inferior">
      <div class="conteudo-footer-inferior">
        <span v-html="this.direitosReservados"></span>
        <span v-html="this.devBy"></span>
      </div>
    </div>
  </footer>
</template>

<script>
import RedesSociaisComponent from './RedesSociaisComponent.vue';


export default {
  name: "FooterComponent",
  computed: {
    direitosReservados() {
      return `© ${new Date().getFullYear()} - Todos os direitos reservados.`;
    },
    devBy() {
      return `Desenvolvido por <a href="https://br.linkedin.com/in/cristian-venturi-3aa689119" target="_blanck">Cristian Venturi</a>`;
    },
  },
  components: { RedesSociaisComponent }
}
</script>

<style lang="scss">
.footer-website {
  width: 100%;

  .container.container-redes-sociais {
    padding: 0rem 0 2rem 0;
  }

  .image-container-logo {
    width: 10rem;
    position: relative;
    padding: 1rem 0;
    height: 100%;
    z-index: 1;
  }

  .footer-superior,
  .footer-inferior {
    width: 100%;
  }

  .footer-superior {
    box-shadow: 0px -3px 15px 0px var(--cor-tema-shadow);
    position: relative;
    overflow: hidden;
    background-image: url(@/assets/images/old-paper-rodape.webp);
    background-size: 100%;

    .conteudo-footer-superior {
      position: relative;
      margin: 0 auto;
      display: grid;
      align-items: center;
      max-width: var(--width-desktop-max);
      grid-template-columns: repeat(2, 1fr);
      color: var(--cor-tema-base);
      padding: 1.5rem 0;
      height: max-content;

      .titulo {
        font-weight: 500;
        font-size: 1.2rem;
        color: var(--cor-tema-base);
        padding-bottom: 0.4rem;
      }

      .item-titulo {
        font-weight: 500;
        color: #4f4f4f;
      }

      .item-conteudo {
        font-weight: 500;
        color: var(--cor-tema-terciaria);
      }

      .grupo-footer,
      .grupo-footer .grupo-item {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .footer-inferior {
    height: 3rem;
    background-color: var(--cor-tema-base);
    box-shadow: 0 0 5px 0 var(--cor-tema-shadow);
    
    a {
      text-decoration: none;
      color: var(--cor-tema-terciaria);
    }
    
    .conteudo-footer-inferior {
      color: var(--cor-texto);
      font-size: .8rem;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: var(--width-desktop-max);
      position: relative;
      margin: 0 auto;
      padding: 0 .5rem;
    }
  }
}

@media (max-width: 650px) {
  .footer-website {
    .conteudo-footer-superior {
      gap: 1rem;
      grid-template-columns: auto !important;
    }
    
    .footer-inferior {
      height: 6rem;

      .conteudo-footer-inferior {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
</style>