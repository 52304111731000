<template>
  <div class="container container-produto">
    <h3 class="titulo"> Nossos Produtos </h3>
    <div class="conteudo">
      <p class="paragrafo-descricao"> Conheça nossas duas principais linhas de Produtos. </p>
      <div class="linha-produtos">

        <router-link 
          v-for="linha in this.linha_produto"
          :class="`linha linha-${linha.nome}`"
          :to="`/linha-de-produto/${linha.nome}`"
        >
          <div class="capa-produto">
            <img 
              :src="`/assets/images/${linha.nome}/capa.webp`" 
              :alt="`linha-${linha.nome}`"
            >
          </div>

          <div class="conteudo-descricao-linha">
            <h4 class="titulo-linha"> {{ linha.titulo }} </h4>
            <p class="descricao-linha"> {{ linha.descricao }} </p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProdutoComponent",
  data() {
    return {
      linha_produto: [
        {nome: 'defumada', titulo: 'Linha Defumada', descricao: `Linguiças, Bacon, Costelas, Kit's feijoada e mais...` },
        {nome: 'frescal', titulo: 'Linha Frescal', descricao: `Linguicinhas para churrasco, morcelas e mais...` },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.container-produto {

  .conteudo {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .linha-produtos {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(2, auto);

      .linha {
        text-decoration: none;
        color: white;
        border-radius: 0.5rem;
        display: grid;
        box-shadow: 0 0 15px -5px var(--cor-tema-shadow);
        background: rgba(110, 79, 57, 0.25);

        .capa-produto {
          overflow: hidden;
          border-radius: 0.5rem 0.5rem 0 0;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .conteudo-descricao-linha {
          color: #2c3e50;
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          border-radius: 0 0 0.5rem 0.5rem;

          .titulo-linha {
            color:#2e4e28;
            font-weight: 500 !important;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {

  .container-produto {

    .conteudo {
      .linha-produtos {
        grid-template-columns: auto;
      }
    }
  }
}
</style>